import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import CarReport from "./Pages/CarReport";
import HeavyDutyReport from "./Pages/HeavyDutyReport";
import BikeReport from "./Pages/BikeReport";
import SignUpForm from "./Pages/SignUpForm";
import Contact from "./Pages/Contact";
import About from "./Pages/About";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import Revolut from "./Pages/Revolut";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import Stripe from "./Pages/Stripe";

//EMWVeCcIexjLx0BuDLJeuKBEy1j-BRijB-N_uDuEd7qAQzCIafrCoy3np0CkW1m6SYotBo67Gk6Iv8Vo


const App = () => {
  

  return (
    <>
      <PayPalScriptProvider options={{clientId:'AeQ8JpFV3gE9ZL2op1sCXopahl4SXz37GNZgTxaX9ro3Rk7Iq-0NJV3lsvLSEwlo0Nr-fyMgpTArbM27'}}>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/car-report" element={<CarReport />} />
          <Route
            path="/heavy-duty-truck-report"
            element={<HeavyDutyReport />}
          />
          <Route path="/motor-bikes-reports" element={<BikeReport />} />
          <Route path="/signup-form" element={<Revolut />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/revolut" element={<Revolut />} />
          <Route path="/stripe" element={<Stripe />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
        <Footer />
      </PayPalScriptProvider>
    </>
  );
};

export default App;
